import React from 'react';
import {getRoutes} from "../../components/shared/routes";
import Layout from "../../components/shared/Layout";
import SEO from "../../components/shared/SEO";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import TimberIDEUDRPlatform from "../../components/sections/TimberIDEUDRPlatform/TimberIDEUDRPlatform";


const TimberIDEUDRPlatformPage = () => {
    const routes = getRoutes();
    const pageTitle = routes.OfferingTimberIDEUDRPlatform.pageTitle;

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <Breadcrumbs
                title={pageTitle}
                parentTitles={[routes.Offering.pageTitle]}
                parentRoutes={[routes.Offering]}
            />

            <TimberIDEUDRPlatform/>

        </Layout>
    );
};

export default TimberIDEUDRPlatformPage;
